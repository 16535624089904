import { useState, useEffect } from 'react'

const useWindowHeight = () => {
  const [windowHeight, setWindowHeight] = useState<number | null>(null)

  useEffect(() => {
    // Este efecto solo se ejecuta en el cliente
    const handleResize = () => {
      setWindowHeight(window.innerHeight)
    }

    // Si estamos en el lado del cliente, obtenemos el tamaño de la ventana
    if (typeof window !== 'undefined') {
      setWindowHeight(window.innerHeight)

      // Escuchamos los cambios en el tamaño de la ventana
      window.addEventListener('resize', handleResize)

      // Limpieza del evento cuando el componente se desmonta
      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }
  }, [])

  return windowHeight
}

export default useWindowHeight
