import { z } from 'zod'

const optionalDecimal = z
  .string()
  .regex(/^[+-]?\d{0,9}(\.\d{0,2})?$/, 'Solo números hasta 999999999,99')
  .or(z.literal(''))
  .optional()
  .nullable()

export const UpdateCaseVarsSchema = z.object({
  attorneyIncluded: z.boolean().optional().nullable(),
  inabilityCoefficientLab: optionalDecimal,
  inabilityCoefficientCompany: optionalDecimal,
  appraisedPropertyDamage: optionalDecimal,
  insuredAmount: optionalDecimal,
  injuredCount: z
    .string()
    .regex(/^[0-9]*$/, 'Solo números')
    .optional()
    .transform((val) => Number(val))
    .nullable(),
  labOverwrittenFormulaResult: optionalDecimal,
  companyOverwrittenFormulaResult: optionalDecimal.refine((val) => {
    if (!val) return true
    return Number(val) >= 0
  }, 'Debe ser mayor o igual a 0'),
  savingDescription: z.string().optional().nullable(),
})

export interface IUpdateCaseVarsSchema
  extends z.infer<typeof UpdateCaseVarsSchema> {}
