import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  chakra,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueryClient } from '@tanstack/react-query'
import { AsyncSelect } from 'chakra-react-select'
import {
  AuthenticationClient,
  buildLimitsQueryKey,
  CompanyClient,
  IContact,
  ILimit,
  IUser,
  LimitClient,
} from 'kach-clients'
import { translator } from 'kach-commons'
import { insurerConditions, intervalType, labConditions } from 'kach-constants'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FaTrashAlt } from 'react-icons/fa'
import { LIMITS_QUERY_KEY } from '../constants/query-keys'
import { useError } from '../hooks/useError'
import { useReferenceTables } from '../hooks/useReferenceTables'
import { CreateLimitSchema, createLimitSchema } from '../schemas/limit.schema'

export const LimitFormBody = ({
  defaultValues,
  onSubmit,
  formId,
  users,
  contacts,
}: {
  defaultValues?: Partial<CreateLimitSchema>
  onSubmit: (data: CreateLimitSchema) => void
  formId: string
  users?: IUser[]
  contacts?: IContact[]
}) => {
  const form = useForm<CreateLimitSchema>({
    defaultValues: {
      name: defaultValues?.name,
      conditions: defaultValues?.conditions ?? [],
      type: defaultValues?.type,
      limit: defaultValues?.limit,
      users: defaultValues?.users,
      contacts: defaultValues?.contacts,
      interval: defaultValues?.interval,
      intervalValue: defaultValues?.intervalValue,
    },
    resolver: zodResolver(createLimitSchema),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = form

  const { referenceTables } = useReferenceTables()

  const fields = watch()

  const allowedConditions = useMemo(() => {
    switch (fields.type) {
      case 'insurer':
        return insurerConditions

      case 'lab':
        return labConditions

      default:
        return []
    }
  }, [fields.type])

  return (
    <FormProvider {...form}>
      <chakra.form
        id={formId}
        onSubmit={handleSubmit(onSubmit)}
        display={'flex'}
        flexDir='column'
        experimental_spaceY={'4'}
      >
        <Box display={'flex'} experimental_spaceX={'4'}>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel>Nombre</FormLabel>
            <Input {...register('name')} />
            {errors.name?.message && (
              <FormErrorMessage>{errors.name.message}</FormErrorMessage>
            )}
          </FormControl>
        </Box>
        <Box>
          <FormControl isInvalid={!!errors.type}>
            <FormLabel>Tipo</FormLabel>
            <Select {...register('type')} placeholder='Tipo'>
              <option value={'lab'}>{translator(`limit_type_lab`)}</option>
            </Select>
            {errors.type?.message && (
              <FormErrorMessage>{errors.type.message}</FormErrorMessage>
            )}
          </FormControl>
        </Box>
        {fields.type === 'lab' && (
          <FormControl isInvalid={!!errors.contacts}>
            <FormLabel>Estudios</FormLabel>
            <AsyncSelect
              isMulti
              cacheOptions
              defaultValue={contacts?.map((contact) => ({
                value: contact.id,
                label: `${contact.name} (${contact.email})`,
              }))}
              defaultOptions
              loadOptions={(inputValue, callback) => {
                return CompanyClient.searchContactsForCaseDerivation(
                  inputValue,
                ).then((res) =>
                  res.results.map((r) => ({
                    value: r.id,
                    label: `${r.name} (${r.email})`,
                  })),
                )
              }}
              placeholder='Busca un estudio...'
              onChange={(v) => {
                setValue(
                  'contacts',
                  v.map((value) => ({
                    id: value.value,
                  })),
                )
              }}
            />
          </FormControl>
        )}
        {fields.type === 'insurer' && (
          <FormControl isInvalid={!!errors.users}>
            <FormLabel>Analistas</FormLabel>
            <AsyncSelect
              isMulti
              cacheOptions
              defaultValue={users?.map((user) => ({
                value: user.id,
                label: `${user.firstName} ${user.lastName} (${user.email})`,
              }))}
              defaultOptions
              loadOptions={(inputValue, callback) => {
                return AuthenticationClient.searchUsers(inputValue).then(
                  (res) =>
                    res.results.map((r) => ({
                      value: r.id,
                      label: `${r.firstName} ${r.lastName} (${r.email})`,
                    })),
                )
              }}
              placeholder='Busca un analista...'
              onChange={(v) => {
                setValue(
                  'users',
                  v.map((value) => ({
                    id: value.value,
                  })),
                )
              }}
            />
          </FormControl>
        )}
        <Box display={'flex'} flexDir={'row'} experimental_spaceX={'2'}>
          <FormControl isInvalid={!!errors.interval}>
            <FormLabel>Intervalo</FormLabel>
            <Select {...register('interval')} placeholder='Intervalo'>
              {intervalType.map((interval, index) => {
                return (
                  <option key={index} value={interval}>
                    {translator(interval)}
                  </option>
                )
              })}
            </Select>
            {errors.interval?.message && (
              <FormErrorMessage>{errors.interval.message}</FormErrorMessage>
            )}
          </FormControl>
          {form.watch('interval') !== 'ever' && (
            <FormControl isInvalid={!!errors.intervalValue}>
              <FormLabel>Valor de intervalo</FormLabel>
              <Input
                value={fields.intervalValue}
                onChange={(v) =>
                  setValue('intervalValue', parseInt(v.target.value))
                }
                type='number'
              />
            </FormControl>
          )}
        </Box>
        <Box>
          <FormControl isInvalid={!!errors.limit}>
            <FormLabel>Cantidad máxima de casos</FormLabel>
            <Input
              value={fields.limit}
              onChange={(v) => {
                setValue('limit', parseInt(v.target.value))
              }}
              type='number'
            />
            {errors.limit?.message && (
              <FormErrorMessage>{errors.limit.message}</FormErrorMessage>
            )}
          </FormControl>
        </Box>
        {/* <Divider /> */}
        {/* <ConditionGrid allowedConditions={allowedConditions} /> */}
      </chakra.form>
    </FormProvider>
  )
}

export const CreateLimitForm = ({
  isOpen,
  onClose,
  companyId,
}: {
  onClose: () => void
  isOpen: boolean
  companyId: number
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const error = useError()

  const queryClient = useQueryClient()

  const onSubmit = useCallback(
    (data: CreateLimitSchema) => {
      setIsLoading(true)

      toast.promise(
        LimitClient.create({
          limit: data,
        }),
        {
          error: (err) => {
            return { title: error.getErrorMessage(err) }
          },
          loading: {
            title: 'Creando recurso',
          },
          success: (res) => {
            queryClient.invalidateQueries([LIMITS_QUERY_KEY])
            queryClient.invalidateQueries([buildLimitsQueryKey(companyId)])

            onClose()

            return {
              title: 'Límite de derivación correctamente',
            }
          },
        },
      )

      setIsLoading(false)
    },
    [companyId],
  )

  const formId = 'create-limit-form'

  return (
    <Modal size='3xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Crear límite de derivación</ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <LimitFormBody formId={formId} onSubmit={onSubmit} />
        </ModalBody>

        <ModalFooter>
          <Button
            variant={'primary'}
            isLoading={isLoading}
            form={formId}
            type='submit'
            mr={3}
          >
            Crear
          </Button>
          <Button variant='ghost' onClick={() => onClose()}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const UpdateLimitForm = ({
  isOpen,
  onClose,
  companyId,
  limit,
}: {
  onClose: () => void
  isOpen: boolean
  companyId: number
  limit: ILimit
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const toast = useToast()

  const error = useError()

  const queryClient = useQueryClient()

  const onUpdate = useCallback(
    (data: CreateLimitSchema) => {
      setIsLoading(true)

      toast.promise(
        LimitClient.update({
          limit: data,
          id: limit.id,
        }),
        {
          error: (err) => {
            return { title: error.getErrorMessage(err) }
          },
          loading: {
            title: 'Actualizando recurso',
          },
          success: (res) => {
            queryClient.invalidateQueries([LIMITS_QUERY_KEY])
            queryClient.invalidateQueries([buildLimitsQueryKey(companyId)])

            onClose()

            return {
              title: 'Limite de derivación actualizado correctamente',
            }
          },
        },
      )

      setIsLoading(false)
    },
    [companyId, limit],
  )

  const formId = 'update-limit-form'

  const defaultValues: Partial<CreateLimitSchema> = useMemo(() => {
    return {
      name: limit.name,
      type: limit.type,
      limit: limit.limit,
      conditions: limit.conditions,
      users: limit.users,
      contacts: limit.contacts,
      interval: limit.interval,
      intervalValue: limit.intervalValue,
    }
  }, [limit])

  return (
    <Modal size='3xl' isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Actualizar limite de derivación</ModalHeader>

        <ModalCloseButton />
        <ModalBody>
          <LimitFormBody
            formId={formId}
            onSubmit={onUpdate}
            defaultValues={defaultValues}
            key={limit.id}
            users={limit.users}
            contacts={limit.contacts}
          />
        </ModalBody>

        <ModalFooter>
          <Button
            variant={'primary'}
            isLoading={isLoading}
            form={formId}
            type='submit'
            mr={3}
          >
            Actualizar
          </Button>
          <Button variant='ghost' onClick={() => onClose()}>
            Cancelar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const DeleteLimitForm = ({
  isOpen,
  onOpen,
  onClose,
  limit,
  companyId,
}: {
  companyId: number
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
  limit: ILimit
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const cancelRef = React.useRef()

  const toast = useToast()

  const error = useError()

  const queryClient = useQueryClient()

  const onDelete = useCallback(() => {
    setIsLoading(true)

    toast.promise(
      LimitClient.delete({
        id: limit.id,
      }),
      {
        error: (err) => {
          return { title: error.getErrorMessage(err) }
        },
        loading: {
          title: 'Eliminando recurso',
        },
        success: (res) => {
          queryClient.invalidateQueries([LIMITS_QUERY_KEY])
          queryClient.invalidateQueries([buildLimitsQueryKey(companyId)])

          onClose()

          return {
            title: 'Limite de derivación eliminado correctamente',
          }
        },
      },
    )

    setIsLoading(false)
  }, [limit])

  return (
    <>
      <IconButton
        onClick={onOpen}
        aria-label='eliminar'
        variant={'ghost'}
        icon={<FaTrashAlt fontSize={'1rem'} />}
      />

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Eliminar limite
            </AlertDialogHeader>

            <AlertDialogBody>
              Esta seguro que desea eliminar el limite de derivación?
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancelar
              </Button>
              <Button colorScheme='red' onClick={onDelete} ml={3}>
                Eliminar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}
