import { companyTypes, intervalType } from 'kach-constants'
import { z } from 'zod'
import { conditionsSchema } from './condition-line.schema'
import { REQUIRED_FIELD_MESSAGE } from './invitation.schema'

export const createLimitSchema = conditionsSchema
  .omit({
    conditions: true,
  })
  .extend({
    name: z.string().min(1, REQUIRED_FIELD_MESSAGE),
    limit: z
      .number({
        required_error: REQUIRED_FIELD_MESSAGE,
      })
      .min(1, {
        message: REQUIRED_FIELD_MESSAGE,
      }),
    type: z.enum(companyTypes, {
      errorMap: (issue, ctx) => ({ message: REQUIRED_FIELD_MESSAGE }),
    }),
    interval: z.enum(intervalType, {
      errorMap: (issue, ctx) => ({ message: REQUIRED_FIELD_MESSAGE }),
    }),
    intervalValue: z.number().nullish(),
    contacts: z
      .array(
        z.object({
          id: z.number().min(1, REQUIRED_FIELD_MESSAGE),
        }),
      )
      .optional(),
    users: z
      .array(
        z.object({
          id: z.number().min(1, REQUIRED_FIELD_MESSAGE),
        }),
      )
      .optional(),
  })
  .refine(
    (data) => {
      if (data.interval === 'ever') return true

      return data.intervalValue != null
    },
    {
      path: ['intervalValue'],
      message: 'Campo requerido!',
    },
  )

export type CreateLimitSchema = z.infer<typeof createLimitSchema>
