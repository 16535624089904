import { differenceInBusinessDays, formatDistance, isToday } from 'date-fns';
import { enforceDateTimeBoundary } from './enforce-datetime-boundary';

// TODO: Test this function
export const timeAgoFormat = (time: string | Date) =>
  formatDistance(new Date(time), new Date(), {
    addSuffix: true,
  });

// TODO: Test this function
export const timeAgoFormatBusinessDays = (
  dateTime: string | Date,
  toDateTime: Date = new Date(),
  timeBoundary?: [string, string]
) => {
  const convertedDateTime = enforceDateTimeBoundary(dateTime, timeBoundary);
  const timeZoneOffsetMs = convertedDateTime.getTimezoneOffset() * 60 * 1000;
  convertedDateTime.setTime(convertedDateTime.getTime() - timeZoneOffsetMs);

  if (isToday(convertedDateTime)) return 'Hoy';

  const businessDays = differenceInBusinessDays(toDateTime, convertedDateTime);

  if (businessDays <= 0) return 'Menos de 1 día';

  return `Hace ${businessDays} días`;
};
