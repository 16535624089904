import {
  ArrowsPointingOutIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import {
  Box,
  CloseButton,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Icon,
  IconButton,
  Tooltip,
} from '@chakra-ui/react'
import { buildTestId } from 'kach-commons'
import { CustomScrollBox } from './CustomScrollBox'

export const RecordPreview: React.FC<
  React.PropsWithChildren<{
    detailLink: string
    onNavigate: (href: string) => void
    onClose: () => void
    open: boolean
    toggle: () => void
    previewSize: string
    setPreviewSize: React.Dispatch<React.SetStateAction<'md' | 'full'>>
  }>
> = (props) => {
  return (
    <Drawer
      onClose={props.onClose}
      isOpen={props.open}
      size={props.previewSize || 'md'}
    >
      <DrawerOverlay />
      <DrawerContent transform='none !important'>
        <Box display='flex' justifyContent='start' p={2} mb={2}>
          <Tooltip label=''>
            <IconButton
              size='sm'
              variant='ghost'
              aria-label='close content'
              icon={<Icon as={ChevronDoubleRightIcon} />}
              mr={2}
              onClick={props.onClose}
              colorScheme='gray'
              autoFocus={false}
            />
          </Tooltip>
          <Tooltip label='Cerrar'>
            <IconButton
              size='sm'
              variant='ghost'
              aria-label='close content'
              icon={<Icon as={ChevronDoubleRightIcon} />}
              ml='-40px'
              mr={2}
              onClick={props.onClose}
              colorScheme='gray'
              autoFocus={false}
            />
          </Tooltip>
          <Tooltip
            label={
              props.previewSize === 'md'
                ? 'Abrir en pantalla completa'
                : 'Volver a vista previa'
            }
          >
            <IconButton
              size='sm'
              variant='ghost'
              aria-label='expand content'
              icon={<Icon as={ArrowsPointingOutIcon} />}
              mr={2}
              onClick={() => {
                props.previewSize === 'md'
                  ? props.setPreviewSize('full')
                  : props.setPreviewSize('md')
              }}
              colorScheme='gray'
            />
          </Tooltip>
        </Box>
        <CustomScrollBox>
          <DrawerBody overflowX='hidden'>{props.children}</DrawerBody>
        </CustomScrollBox>
      </DrawerContent>
    </Drawer>
  )
}
