import React, { useCallback } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { IFilter, IFiltersForm } from '../components/TableAbstract'

const FiltersControlContext = React.createContext<ReturnType<
  typeof useFilters
> | null>(null)

export const useFiltersContext = () => React.useContext(FiltersControlContext)!

export const FiltersControlProvider = ({
  children,
  ...filtersControl
}: { children: React.ReactNode } & ReturnType<typeof useFilters>) => {
  return (
    <FiltersControlContext.Provider value={filtersControl}>
      {children}
    </FiltersControlContext.Provider>
  )
}

export type FiltersControl = ReturnType<typeof useFilters>

export const useFilters = (
  props: {
    defaultValues?: Partial<IFiltersForm>
    columnsLiteralIds?: string[]
  } = {},
) => {
  const {
    setValue: setFilters,
    control,
    watch: filtersObserver,
    register: registerFilter,
  } = useForm<IFiltersForm>({
    defaultValues: props.defaultValues || {
      filters: [],
    },
  })

  const { append: onAddFilter, remove: onRemoveFilter } = useFieldArray({
    control,
    name: 'filters',
  })

  const fields = filtersObserver()

  const serializeFilters = useCallback((input: IFilter[]): object => {
    return input
      .filter(
        (ele) => !!ele.value || ele.enums?.length > 0 || !!ele.from || !!ele.to,
      )
      .reduce((acc, curr) => {
        switch (curr.type) {
          case 'range-slider':
            return {
              ...acc,
              [curr.column]: curr.value,
            }

          case 'date':
            return {
              ...acc,
              [curr.column]: {
                from: curr.from
                  ? new Date(
                      new Date(curr.from).valueOf() +
                        new Date().getTimezoneOffset() * 60000,
                    )
                  : curr.from,
                to: curr.to
                  ? new Date(
                      new Date(curr.to).valueOf() +
                        new Date().getTimezoneOffset() * 60000,
                    )
                  : curr.to,
              },
            }

          case 'async-enum':
            return {
              ...acc,
              [curr.column]: curr.enums
                ?.filter((ele) => ele && ele.id)
                .map((ele) => ele.id),
            }

          case 'enum':
            return {
              ...acc,
              [curr.column]: curr?.enums
                .map((ele, index) => {
                  const optSelected = curr?.enumData?.find(
                    (_, indexSSOT) => indexSSOT === index,
                  )

                  return {
                    ...ele,
                    //@ts-ignore
                    id: optSelected.id || optSelected.value,
                    name: (props.columnsLiteralIds || []).includes(curr.column)
                      ? optSelected?.value
                      : typeof optSelected?.value === 'number'
                      ? optSelected.name
                      : optSelected?.value,
                  }
                })
                .filter((ele) => ele.checked)
                .map((ele) => ({
                  name: ele.name,
                  id: ele.id,
                })),
            }

          case 'sort':
          case 'text':
            return {
              ...acc,
              [curr.column]: curr.value,
            }
        }
      }, {})
  }, [])

  return {
    setFilters,
    onAddFilter,
    onRemoveFilter,
    filtersObserver,
    registerFilter,
    filters: filtersObserver('filters'),
    serializeFilters,
    fields,
  }
}
